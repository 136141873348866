import { z } from 'zod'
import { AsyncFn } from '../../common'
import { bzOptional } from '../common-schemas'
import { ForCompanyUser } from '../Company/Company'

export const PROSPECT_NAME_PLACEHOLDER = '[Prospect First Name]'
export const AMAZON_GIFT_CARD_URL = 'https://breezy-prod-files.s3.amazonaws.com/amazon_logo.svg'
export const SendReferralRequestSchema = z.object({
  deliveryMethod: z.enum(['EMAIL', 'SMS']),
  body: z.string(),
  to: z.string(),
  recipientName: z.string(),
  subject: bzOptional(z.string()),
})

export type SendReferralRequest = z.infer<typeof SendReferralRequestSchema>

export type BreezyReferralSender = AsyncFn<ForCompanyUser<SendReferralRequest>>
